// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-product-stacking-crate-graphql-js": () => import("./../src/templates/product-stacking-crate-graphql.js" /* webpackChunkName: "component---src-templates-product-stacking-crate-graphql-js" */),
  "component---src-templates-product-folding-crate-graphql-js": () => import("./../src/templates/product-folding-crate-graphql.js" /* webpackChunkName: "component---src-templates-product-folding-crate-graphql-js" */),
  "component---src-templates-product-nesting-box-graphql-js": () => import("./../src/templates/product-nesting-box-graphql.js" /* webpackChunkName: "component---src-templates-product-nesting-box-graphql-js" */),
  "component---src-templates-product-pallet-box-graphql-js": () => import("./../src/templates/product-pallet-box-graphql.js" /* webpackChunkName: "component---src-templates-product-pallet-box-graphql-js" */),
  "component---src-templates-tags-js": () => import("./../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-bulk-containers-wholesale-index-js": () => import("./../src/pages/bulk-containers-wholesale/index.js" /* webpackChunkName: "component---src-pages-bulk-containers-wholesale-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-distribution-containers-index-js": () => import("./../src/pages/distribution-containers/index.js" /* webpackChunkName: "component---src-pages-distribution-containers-index-js" */),
  "component---src-pages-euro-containers-index-js": () => import("./../src/pages/euro-containers/index.js" /* webpackChunkName: "component---src-pages-euro-containers-index-js" */),
  "component---src-pages-folding-plastic-box-index-js": () => import("./../src/pages/folding-plastic-box/index.js" /* webpackChunkName: "component---src-pages-folding-plastic-box-index-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

